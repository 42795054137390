import React from "react";
import { useHistory } from "react-router";

import EmptyStateIcon from "assets/vectors/recorded-content/course_analytics_empty_state.svg";
import ExlyTable from "common/Components/ExlyTable";
import {
  ActionField,
  CertificateStatusField,
  DaysFilter,
  Stats,
} from "./components/HelperComponents/HelperComponents";

import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import useCourseAnalytics from "./useCourseAnalytics";

import BroadcastModal from "common/Components/BroadcastModal/BroadcastModal";
import { app_route_keys } from "constants/urlPaths";
import { orgPermissions } from "utils/OrgPermissions";
import { is_empty } from "utils/validations";
import { WhatsAppButton } from "webpage-leads/components/CustomColumns";
import {
  columnKeys,
  COURSE_ANALYTICS_LEARN_MORE_HREFS,
  filterConfig,
  getColumnConfig,
  tableChipsFormatter,
  VIEW_EXAM_FORM_RESPONSES_LABEL,
} from "./data";
import { courseAnalyticsExporter } from "./utils";
import styles from "./courseAnalytics.module.css";
import { RESPONSES } from "../ContentResponses/data";
import LearnMoreCta from "features/Common/modules/LearnMore/modules/LearnMoreCta/LearnMoreCta";
import { EXLY_EMPTY_STATE_SECONDARY_CTA_VARIANTS } from "features/Common/modules/ExlyEmptyState/constants/ExlyEmptyState.constants";
import { EXLY_TABLE_EMPTY_STATES_VERSIONS } from "common/Components/ExlyTable/ExlyTableEmptyState/constants/ExlyTableEmptyState.constants";

const CourseAnalytics = (props) => {
  const {
    aggregateData,
    broadcastActions,
    canBroadcastMessage,
    onSelect,
    isDesktop,
    selectedIds,
    hasCertification,
    hasExams,
    hasAssessments,
    hasManualEvaluation,
    loading,
    aggregateDaysFilter,
    handleDaysFilter,
    totalLearners,
    listingUuid,
    isEmptySelectedIds,
  } = useCourseAnalytics(props);
  const history = useHistory();

  const BroadcastCta = (
    <BroadcastModal
      modal_props={{ title: "Send Message" }}
      mobile_modal_props={{ header: "Select Broadcast medium" }}
      broadcastActions={broadcastActions}
      cta_props={{
        children: "Send Message",
        fullWidth: !isDesktop,
        enableCheckSelectedIdsEmpty: true,
        isEmptySelectedIds: isEmptySelectedIds,
        size: isDesktop ? "medium" : "thin",
      }}
    />
  );

  const renderSecondaryAction = hasCertification
    ? (record) => (
        <CertificateStatusField record={record} isDesktop={isDesktop} />
      )
    : undefined;

  const handleViewResponseClick = (record) => {
    let responses = RESPONSES.FORMS;
    if (!record[columnKeys.total_forms] && hasExams)
      responses = RESPONSES.EXAMS;

    history.push(
      `/${app_route_keys.recorded_content_responses}/${
        record[columnKeys.customerName]
      }/${responses}/${listingUuid}/${record[columnKeys.booking_uid]}`
    );
  };

  return (
    <ExlyTable
      ra_props={{
        ...props,
        exporter: orgPermissions.canExport()
          ? courseAnalyticsExporter({ hasExams, hasCertification })
          : false,
      }}
      noExportButton={!orgPermissions.canExport()}
      tableTitle="Course Analytics"
      layoutProps={{
        secondaryComponent: (
          <div className="d-flex">
            {isDesktop && canBroadcastMessage && (
              <>
                <LearnMoreCta
                  href={COURSE_ANALYTICS_LEARN_MORE_HREFS.COURSE_ANALYTICS}
                  className="mr-3"
                />
                {BroadcastCta}
              </>
            )}
          </div>
        ),
        showBackCta: true,
        paddingBottom: "120px",
        layoutDesktopMargin: "20px 24px",
        learnMoreHref: COURSE_ANALYTICS_LEARN_MORE_HREFS.COURSE_ANALYTICS,
      }}
      statsComponent={() => (
        <>
          {!is_empty(aggregateData) && (
            <DaysFilter
              isDesktop={isDesktop}
              value={aggregateDaysFilter}
              onChange={handleDaysFilter}
            />
          )}
          <Stats
            stats={aggregateData}
            isDesktop={isDesktop}
            hasCertification={hasCertification}
            hasAssessments={hasAssessments}
            loading={loading}
          />
          <div className={styles.learners}>Total learners: {totalLearners}</div>
        </>
      )}
      selected={selectedIds}
      onRowSelected={onSelect}
      columnConfig={getColumnConfig(
        hasCertification,
        hasExams,
        handleViewResponseClick
      )}
      tableFilters={filterConfig({ hasManualEvaluation })}
      tableChipsFormatter={tableChipsFormatter}
      renderSecondaryAction={renderSecondaryAction}
      customPrimaryAction={{
        label: <ActionField label={VIEW_EXAM_FORM_RESPONSES_LABEL} />,
        onClick: handleViewResponseClick,
      }}
      renderPrimaryColumnRightNode={(record) =>
        is_empty(record.customer_phone_number) ? null : (
          <WhatsAppButton
            record={record}
            source="customer_phone_number"
            appendCountryCode={false}
          />
        )
      }
      checkboxSelection
      showSelectAllcheckBox={true}
      borderedFields
      fieldsLeftPadded
      drawerFieldsBordered
      fieldsAlignment="space-between"
      drawerFieldsAlignment="space-between"
      recordFooterVariant="secondary"
      primaryKey="id"
      emptyStateProps={{
        version: EXLY_TABLE_EMPTY_STATES_VERSIONS.V2,
        title: "No students yet.",
        description:
          "All the students of this course will get collected here. You’ll be able to view overall course analytics regarding your engaged users, assessment submitted, certificated issued as well as course progress and assessment responses for each student!",
        imgSrc: EmptyStateIcon,
        secondaryCtaProps: {
          variant: EXLY_EMPTY_STATE_SECONDARY_CTA_VARIANTS.learn_more,
          learnMoreHref: COURSE_ANALYTICS_LEARN_MORE_HREFS.COURSE_ANALYTICS,
        },
        className: "mt-3",
      }}
      hasMobileFooter
      footerProps={{
        showPrimaryBtn: true,
        primaryBtnJSX: BroadcastCta,
      }}
    />
  );
};

export default withComponentLibraryTheme(CourseAnalytics);
