import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { useListController } from "react-admin";

import { RESOURCE_KEYS, orgPermissions } from "utils/OrgPermissions";
import { notification_color_keys, useNotifications } from "utils/hooks";
import { isValidEmail, is_empty } from "utils/validations";
import { fetchAggregateAnalyticsData } from "./utils";

import { getBroadcastActions } from "../customers/AllCustomers/allCustomersTableConfig";

import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import { DEFAULT_PER_PAGE } from "constants/numbers";

const useCourseAnalytics = (props) => {
  const { listing_uuid: listingUuid } = useParams();

  const [aggregateData, setAggregateData] = useState({});
  const [aggregateDaysFilter, setAggregateDaysFilter] = useState(7);
  const [loading, setLoading] = useState(false);

  const isDesktop = useDesktopMediaQuery();
  const { notify } = useNotifications();
  const { onSelect, selectedIds, loaded, data } = useListController({
    ...props,
    perPage: DEFAULT_PER_PAGE,
  });

  const isEmpty = loaded && is_empty(data);
  const isEmptySelectedIds = is_empty(selectedIds);
  const canSendEmail = orgPermissions.hasFeatureWriteAccess(
    RESOURCE_KEYS.SECTIONS.CUSTOM_MAILS
  );
  const canSendWhatsapp = orgPermissions.hasFeatureWriteAccess(
    RESOURCE_KEYS.SECTIONS.WHATSAPP_BROADCASTS
  );
  const canBroadcastMessage = (canSendEmail || canSendWhatsapp) && !isEmpty;
  const {
    [fetchAggregateAnalyticsData.keys.HAS_CERTIFICATION]: hasCertification,
    [fetchAggregateAnalyticsData.keys.HAS_EXAMS]: hasExams,
    [fetchAggregateAnalyticsData.keys.HAS_ASSESSMENTS]: hasAssessments,
    [fetchAggregateAnalyticsData.keys.hasManualEvaluation]: hasManualEvaluation,
  } = aggregateData;

  const sendEmail = useCallback(() => {
    if (isDesktop && is_empty(selectedIds)) {
      return notify("Please select customers first", "error");
    }

    let recipient_list = [];
    let email_list = [];
    let emailInvalid = false;

    if (isDesktop || !is_empty(selectedIds)) {
      for (let id of selectedIds) {
        let value = data[id];
        let check = email_list.filter(
          (item) => item.email === value.customer_email
        );
        if (!is_empty(check) || is_empty(value.customer_email)) continue;

        if (!isValidEmail(value.customer_email)) emailInvalid = true;
        email_list.push({
          name: value.customer_name,
          email: value.customer_email,
        });
      }
    } else {
      for (let id in data) {
        let value = data[id];
        let check = email_list.filter(
          (item) => item.email === value.customer_email
        );
        if (!is_empty(check) || is_empty(value.customer_email)) continue;

        if (!isValidEmail(value.customer_email)) emailInvalid = true;
        email_list.push({
          name: value.customer_name,
          email: value.customer_email,
        });
      }
    }

    let recipient_list_local = {
      key: "extra",
      emails: email_list,
      display_name: "Selected Customers",
      email_check: email_list,
      invalid: emailInvalid,
    };

    recipient_list.push(recipient_list_local);

    sessionStorage.setItem("recipient_list", JSON.stringify(recipient_list));
    window.location.href = `${window.location.origin}/#/ComposeEmail`;
  }, [selectedIds, isDesktop, data]);

  const sendWhatsappBroadcast = useCallback(() => {
    if (isEmptySelectedIds)
      return notify("Please select customers first", "error");

    const phoneNumberList = selectedIds.map(
      (i) => data[i]?.customer_phone_number
    );

    window.location.href = `${
      window.location.origin
    }/#/whatsapp-broadcasts/select?preselected_numbers=${encodeURIComponent(
      phoneNumberList.join(",")
    )}`;
  }, [selectedIds]);

  const broadcastActions = useMemo(() => {
    return getBroadcastActions({
      sendEmail,
      sendWhatsappBroadcast,
    }).filter((item) => !item.hidden);
  }, [sendEmail, sendWhatsappBroadcast, selectedIds, data]);

  const handleFetchAggregateAnalyticsData = async (props) => {
    setLoading(true);
    try {
      const aggregateAnalyticsData = await fetchAggregateAnalyticsData(props);
      setAggregateData(aggregateAnalyticsData);
    } catch (error) {
      notify(
        "Something went wrong while fetching analytics data.",
        notification_color_keys.error
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleFetchAggregateAnalyticsData({ listing_uuid: listingUuid });
  }, []);

  const handleDaysFilter = (value) => {
    setAggregateDaysFilter(value);
    handleFetchAggregateAnalyticsData({
      listing_uuid: listingUuid,
      days_filters: value,
    });
  };

  return {
    loading,
    aggregateData,
    broadcastActions,
    canBroadcastMessage,
    onSelect,
    isDesktop,
    selectedIds,
    hasCertification,
    hasExams,
    hasAssessments,
    hasManualEvaluation,
    aggregateDaysFilter,
    handleDaysFilter,
    totalLearners: Object.keys(data).length,
    listingUuid,
    isEmptySelectedIds,
  };
};

export default useCourseAnalytics;
