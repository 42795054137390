import React from "react";

import AssessmentIcon from "assets/vectors/recorded-content/assessments_submitted.svg";
import EngagedLearnerIcon from "assets/vectors/recorded-content/engaged_learners.svg";
import StarBadgeIcon from "assets/vectors/recorded-content/star_badge_green.svg";

import {
  ActionField,
  CertificateStatusField,
  CourseProgressField,
} from "./components/HelperComponents/HelperComponents";

import constants from "constants/constants";
import moment from "moment";
import { handleDateFormat } from "utils/Utils";

export const COURSE_ANALYTICS_STATS_KEYS = {
  ENGAGED_LEARNERS: "engaged_learners",
  ASSESSMENTS_SUBMITTED: "assessment_submitted",
  CERTIFICATES_ISSUED: "certificates_issued",
};

export const COURSE_PROGRESS_ENUM = {
  NOT_STARTED: 1,
  IN_PROGRESS: 2,
  COMPLETED: 3,
};

export const COURSE_EVALUATION_STATUS = {
  PENDING: 1,
};

export const CERTIFICATES_ISSUED_STATUS = {
  ISSUED: 1,
  NOT_ISSUED: 2,
};

export const COURSE_ANALYTICS_STATS_DATA = {
  [COURSE_ANALYTICS_STATS_KEYS.ENGAGED_LEARNERS]: {
    label: "Engaged learners",
    tooltip: () =>
      "These are all the students who have purchased the course and started the course as well.",
    icon: EngagedLearnerIcon,
    bgColor: "#E2F6FA",
    color: "#086F86",
  },
  [COURSE_ANALYTICS_STATS_KEYS.ASSESSMENTS_SUBMITTED]: {
    label: "Assessments submitted",
    tooltip: ({ hasAssessments }) =>
      hasAssessments
        ? "These are the total no. of exams and forms submitted by all the students."
        : "Add exams/forms in this course in order to track the total number of assessments submitted.",
    icon: AssessmentIcon,
    bgColor: "#F1EFFF",
    color: "#493AB1",
    showValue: ({ hasAssessments }) => hasAssessments,
  },
  [COURSE_ANALYTICS_STATS_KEYS.CERTIFICATES_ISSUED]: {
    label: "Certificates issued",
    tooltip: ({ hasCertification }) =>
      hasCertification
        ? "These are the total number of certificates issued to the students so far."
        : "Add a certificate for this course in order to track the total certificates issued.",
    icon: StarBadgeIcon,
    bgColor: "#ECFFF9",
    color: "#00A570",
    showValue: ({ hasCertification }) => hasCertification,
  },
};

export const aggregateFilterOptions = [
  {
    id: constants.dashboardFilter.last7days,
    label: "Last 7 days",
    value: constants.dashboardFilter.last7days,
  },
  {
    id: constants.dashboardFilter.last30days,
    label: "Last 30 days",
    value: constants.dashboardFilter.last30days,
  },
  {
    id: parseInt(moment().format("DD")),
    label: "This month",
    value: parseInt(moment().format("DD")),
  },
];

export const columnKeys = {
  customerName: "customer_name",
  customerEmail: "customer_email",
  courseProgress: "content_progress",
  videos: "videos",
  exams: "exams",
  lastVisited: "last_visit",
  isCertificateIssued: "is_certificate_issued",
  lastContentAccessed: "last_content_accessed",
  booking_uid: "booking_uid",

  // exams keys
  attempted: "attempted",
  passed: "passed",
  total: "total",
  total_forms: "total_forms",
};

export const getColumnConfig = (
  hasCertification,
  hasExams,
  handleViewResponseClick
) => {
  const columns = [
    {
      field: columnKeys.customerName,
      headerName: "Customer Name",
      avatar: true,
      fixed: "left",
      noWrap: true,
      isPrimary: true,
    },
    {
      field: columnKeys.courseProgress,
      headerName: "Course progress",
      valueFormatter: (record) => (
        <CourseProgressField
          record={record}
          source={columnKeys.courseProgress}
        />
      ),
      noWrap: true,
    },
    {
      field: columnKeys.lastContentAccessed,
      headerName: "Course status",
      noWrap: true,
      emptyField: "N/A",
    },
    {
      field: columnKeys.lastVisited,
      headerName: "Last visited on",
      valueFormatter: (record) => {
        const { [columnKeys.lastVisited]: lastVisited } = record;
        return lastVisited ? handleDateFormat(lastVisited) : "Not visited yet";
      },
      noWrap: true,
    },
    {
      field: "",
      headerName: "Actions",
      fixed: "right",
      valueFormatter: (record) => (
        <ActionField
          record={record}
          label={VIEW_EXAM_FORM_RESPONSES_LABEL}
          onClick={handleViewResponseClick}
        />
      ),
      hidden: true,
    },
  ];

  if (hasExams)
    columns.splice(3, 0, {
      field: columnKeys.exams,
      headerName: "Exam(s) progress",
      valueFormatter: (record) => {
        const {
          attempted = 0,
          passed = 0,
          total = 0,
        } = record[columnKeys.exams] || {};
        return (
          <div>
            {attempted}/{total} attempted, {passed} passed
          </div>
        );
      },
      noWrap: true,
    });

  if (hasCertification) {
    columns.splice(-1, 0, {
      field: columnKeys.isCertificateIssued,
      headerName: "Certificate status",
      valueFormatter: (record) => <CertificateStatusField record={record} />,
      hidden: true,
      noWrap: true,
    });
  }

  return columns;
};

const filterSourceKeys = {
  daysFilter: "days_filters",
  status: "status",
  courseStatus: "course_status",
  certificateStatus: "certificate_issued_status",
  customerEmail: "customer_email",
  customerPhoneNumber: "customer_phone_number",
  isEvaluationPending: "is_evaluation_pending",
  customerName: "customer_name",
};

export const filterConfig = ({ hasManualEvaluation }) => [
  {
    source: filterSourceKeys.daysFilter,
    placeholder: "Last visit in",
    type: "dropdown",
    disableContains: true,
    options: [
      { id: 1, label: "Last 7 days", value: 7 },
      { id: 2, label: "Last 30 days", value: 30 },
    ],
  },
  {
    source: filterSourceKeys.courseStatus,
    placeholder: "Course Progress",
    type: "dropdown",
    disableContains: true,
    options: [
      { id: 1, label: "Non engaged", value: COURSE_PROGRESS_ENUM.NOT_STARTED },
      { id: 2, label: "In progress", value: COURSE_PROGRESS_ENUM.IN_PROGRESS },
      { id: 2, label: "Completed", value: COURSE_PROGRESS_ENUM.COMPLETED },
    ],
  },
  {
    source: filterSourceKeys.certificateStatus,
    placeholder: "Certificate Status",
    type: "dropdown",
    disableContains: true,
    options: [
      { id: 1, label: "Issued", value: CERTIFICATES_ISSUED_STATUS.ISSUED },
      {
        id: 2,
        label: "Not issued",
        value: CERTIFICATES_ISSUED_STATUS.NOT_ISSUED,
      },
    ],
  },
  {
    source: filterSourceKeys.customerName,
    placeholder: "Customer Name",
    type: "input",
    disableContains: true,
  },
  {
    source: filterSourceKeys.customerEmail,
    placeholder: "Customer Email",
    type: "input",
    disableContains: true,
  },
  {
    source: filterSourceKeys.customerPhoneNumber,
    placeholder: "Customer phone number",
    type: "input",
    disableContains: true,
  },
  {
    source: filterSourceKeys.isEvaluationPending,
    placeholder: "Evaluation",
    type: "dropdown",
    disableContains: true,
    options: [
      { id: 1, label: "Pending", value: COURSE_EVALUATION_STATUS.PENDING },
    ],
    hidden: !hasManualEvaluation,
  },
];

export const tableChipsFormatter = (filter, value) => {
  switch (filter) {
    case filterSourceKeys.daysFilter:
      return `Last visit in: ${value} days`;
    case filterSourceKeys.courseStatus:
      return `Course Progress: ${
        {
          [COURSE_PROGRESS_ENUM.COMPLETED]: "Completed",
          [COURSE_PROGRESS_ENUM.IN_PROGRESS]: "In progress",
          [COURSE_PROGRESS_ENUM.NOT_STARTED]: "Non engaged",
        }[value]
      }`;
    case filterSourceKeys.certificateStatus:
      return `Certificate Status: ${
        value === CERTIFICATES_ISSUED_STATUS.ISSUED ? "Issued" : "Not issued"
      }`;
    case filterSourceKeys.customerEmail:
      return `Customer Email: ${value}`;
    case filterSourceKeys.customerPhoneNumber:
      return `Customer phone number: ${value}`;
    case filterSourceKeys.isEvaluationPending:
      return `Evaluation: ${
        { [COURSE_EVALUATION_STATUS.PENDING]: "Pending" }[value]
      }`;
    case filterSourceKeys.customerName:
      return `Customer Name: ${value}`;
    default:
      return undefined;
  }
};

export const VIEW_EXAM_FORM_RESPONSES_LABEL = "View exam/form responses";
export const COURSE_ANALYTICS_LEARN_MORE_HREFS = {
  COURSE_ANALYTICS:
    "https://support.exlyapp.com/en/support/solutions/articles/84000392230-track-your-course-analytics-on-exly",
};
